.container {
  container-name: mediaGallery;
  container-type: inline-size;
}

.head {
  margin-bottom: var(--section-spacing);

  @media (--small) {
    display: flex;
  }

  & > * {
    @media (--small) {
      width: 50%;
    }

    & p {
      max-width: var(--paragraph-width);

      @media (--large) {
        margin-left: var(--spacing-12);
      }
    }
  }

  & h2 {
    margin-bottom: var(--spacing-24);
    @media (--small) {
      margin: 0;
    }
  }
}

.card {
  position: relative;
}

.swiper {
  padding-left: var(--smart-page-inset);
  padding-right: var(--smart-page-inset);
}

div.slide {
  --slide-width: 70%;
  width: var(--slide-width);

  @media (--small) {
    --slide-width: calc((100%) / 4 - var(--column-gap) / 1.5);
  }
}

.link {
  margin-top: var(--spacing-48);

  & a {
    @media (--small-only) {
      width: 100%;
    }
  }
}
