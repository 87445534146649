.root {
  position: fixed;
  bottom: var(--spacing-48);
  left: 0;
  background-color: black;
  color: white;
  --svg-color: white;
  border: none;
  height: 40px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;
  max-width: 30px;
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  transition: max-width 0.3s ease;
  cursor: pointer;
  &:hover {
    max-width: 100%;
  }

  & span {
    white-space: nowrap;
  }

  & svg {
    display: block;
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
}
