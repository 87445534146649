.filters {
  background: #fff;
}

.module {
  &__footer {
    border-top: 1px solid var(--color-divider);
    padding: var(--spacing-24) var(--page-inset);
    margin-top: auto;
    text-align: center;
  }

  &__footer_inner {
    display: flex;
    gap: var(--column-gap);
    margin-top: var(--spacing-24);
  }

  &__filters {
    padding: 0 var(--page-inset);
  }
}

.item {
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--color-divider);
  padding: var(--spacing-12) 0;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  @extend %typo-body1;

  &:last-child {
    border: 0;
  }

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-black);
    display: block;
  }

  &.active::before {
    background: var(--color-black);
  }

  & div {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
}

.color__indicator {
  height: 20px;
  width: 16px;
  display: block;
  border-radius: 8px;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
}

.modalInner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
